@use "../../index.module.scss" as *;
@use "../../styles/labelStyles.scss" as *;

.accountContainer {
  display: grid;
  grid-template-rows: 34px 1fr;
  row-gap: 5px;
  position: relative;
  .account-header {
    display: grid;
    align-self: start;
    background-color: transparent;
    border-bottom: 6px solid black;
    position: relative;
    label {
      @include label;
      display: grid;
      grid-auto-flow: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      height: 35px;
      z-index: 2;
      span:first-of-type {
        font-family: Russo One;
        color: rgba(var(--darkFontColor), 1);
        z-index: 1;
      }
      span {
        font-family: var(--mainFontFamily);
        color: rgba(var(--darkFontColor), 1);
        z-index: 1;
      }
      .account-header-go-back {
        margin-right: 10px;
        height: 35px;
        width: 35px;
        background-color: var(--primary);
        border-radius: 50%;
        z-index: 5;
        @media #{$laptop} {
          cursor: pointer;
        }
        &:hover {
          path {
            fill: orange;
          }
        }
      }
    }
    .header-bar {
      position: absolute;
      width: 100%;
    }
  }

  .account-main-section {
    display: grid;
    // background-color: rgba(245, 245, 244, 0.1);
    grid-template-rows: auto 60px;
    grid-row: 2;
    grid-column: 1;
    height: calc(100vh - 120px);
    height: calc(100dvh - 120px);
    overflow: auto;
  }
  @media #{$laptop} {
    .account-main-section {
      grid-template-rows: 1fr;
    }
  }
}
