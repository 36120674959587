@use "../../../../styles/labelStyles.scss" as *;
@use "../../../../styles/buttonStyles.scss" as *;
@use "../../../../index.module.scss" as *;

.service-history-container {
  display: grid;
  width: 100vw;
  grid-template-rows: 90px calc(100vh - 200px);
  align-items: center;
  .service-history-tab-section {
    position: absolute;
    top: 39px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(auto, 120px);
    place-content: center;
    background-color: var(--primaryHoverColor);
    width: 100vw;
    grid-gap: 10px;
    z-index: 3;
    .service-history-tab {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      padding: 5px 10px;
      place-content: center;
      span {
        font-size: 0.8rem;
        margin-top: auto;
        padding-top: 0.5rem;
        display: block;
        text-align: center;
        color: var(--primary-mobile);
        font-family: var(--selectedFontFamily);
      }
      svg {
        height: 40px;
        width: 40px;
        align-self: center;
        @media #{$laptop} {
          cursor: pointer;
        }
      }
      &:nth-of-type(3) {
        svg {
          height: 30px;
          cursor: default;
          path {
            fill: grey;
          }
        }
      }
      &.active {
        background-color: var(--primary);
        position: relative;
        svg {
          path {
            fill: orange;
          }
        }
        &::after {
          content: "";
          position: absolute;
          background-color: orange;
          height: 5px;
          width: 100%;
          top: -5px;
        }
      }
      &.all-services-done {
        svg {
          height: 34px;
          width: 34px;
          path {
            fill: mediumaquamarine;
          }
        }
      }
      &:nth-of-type(1) {
        svg {
          height: 31px;
        }
      }
    }
  }
  .service-history-sub-container {
    display: grid;
    grid-row: 2;
    max-height: 100%;
    overflow-y: auto;
  }
  .service-history-list-container {
    display: grid;
    justify-items: center;
    grid-row: 2;
    height: min-content;
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-gap: 5px;
    grid-auto-rows: min-content;
    padding: 15px 0 60px 0;
  }
  .service-history-list-container-new {
    display: grid;
    justify-items: center;
    grid-row: 2;
    height: min-content;
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    grid-gap: 5px;
    grid-auto-rows: min-content;
    padding: 15px 0 36px 0;
  }
  .service-history-item-container-new {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    padding: 0 10px;
    margin-top: 1rem;
    position: relative;
    .service-history-item-wrap {
      display: flex;
      z-index: 1;
    }
    .service-history-item-timestamp {
      display: grid;
      min-width: 7rem;
      grid-template-rows: repeat(3, min-content);
      background-color: white;
      grid-row: 1;
      border-radius: 4px 0 0 4px;
      .service-timestamp-month {
        font: 300 1rem Russo One;
        background-color: orange;
        color: white;
        text-align: center;
        border-radius: 4px 0 0 0px;
      }
      .service-timestamp-date {
        font: 300 2.3rem Russo One;
        text-align: center;
      }
      .service-timestamp-year {
        font: 300 1rem Russo One;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .service-history-item {
      background-color: rgba(var(--primaryRGB), 0.8);
      padding: 10px;
      width: 100%;
      max-width: 600px;
      display: grid;
      align-items: center;
      box-sizing: border-box;
      border-radius: 0px 4px 0px 0px;
      grid-column: 2;
      .service-item-car-wrapper {
        cursor: pointer;
      }
      .service-item-car-wrapper,
      .service-item-user-wrapper {
        grid-row: 2;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: start;
        margin-bottom: 0.6rem;
        cursor: pointer;

        svg {
          height: 20px;
          width: 30px;
          grid-column: 1;
          path {
            fill: orange;
          }
        }
        span {
          align-self: center;
          @include label;
          font-size: 0.8rem;
          width: auto;
          color: white;
          white-space: normal;
        }
      }
      .service-item-user-wrapper {
        grid-row: 1;
      }
      .service-item-car-wrapper {
        background-color: var(--primaryHoverColor);
        border-radius: 5px;
      }
    }
    .service-history-item-status-bar {
      display: grid;
      grid-auto-flow: column;
      padding: 3px 0px;
      align-items: center;
      background-color: rgba(50, 65, 84, 0.7);
      grid-column: 1/3;
      border-radius: 0 0 4px 0;
      &.with-hover {
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
      }
      div {
        border-right: 1px solid darkgrey;
        justify-items: center;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: minmax(0, 1fr) 40px;
        align-items: center;
        &:last-of-type {
          border: none;
        }
        &:first-of-type {
          grid-template-columns: 1fr;
          span {
            padding: 0 5px;
          }
        }
        svg {
          height: 30px;
          width: 30px;
        }
        @media #{$laptop} {
          cursor: pointer;
          span {
            display: block;
          }
        }
        &:hover {
          span {
            font-weight: bold;
            color: mediumaquamarine;
          }
        }
      }
      .status-service-photos,
      .status-service-desc {
        font: 300 1rem var(--mainFontFamily);
        color: gainsboro;
        width: 48px;
        text-align: center;
      }
      .status-service-desc {
        width: 29px;
      }
      .status-desc,
      .status-desc-user {
        @include label;
        width: min-content;
        color: orange;
        text-transform: uppercase;
        justify-self: start;
        padding: 0 10px;
      }
      .status-bar-save-action-wraper {
        span {
          @include label;
          font-size: 0.9rem;
          width: 100%;
          display: block;
        }
      }
    }
    .edit-mode-action-button-new {
      display: flex;
      flex: 1;
      border-radius: 4px;
      margin-top: -5px;
      background-color: var(--primaryHoverColor);
      z-index: 99;
      div.double-action {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 1;
        div {
          padding-top: 1rem;
          text-align: center;
          padding-bottom: 0.5rem;
          span {
            font-size: 0.8rem;
            margin-top: auto;
            padding-top: 0.2rem;
            display: block;
            text-align: center;
            color: orange;
            font-weight: normal;
            font-family: var(--selectedFontFamily);
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      svg {
        height: 35px;
        width: 35px;
        position: relative;
        padding: 2px;
        z-index: 3;
        align-self: center;
        @media #{$laptop} {
          cursor: pointer;
        }
        path {
          fill: #6c7684;
        }
      }
      &.confirmed {
        background-color: mediumaquamarine;
        pointer-events: none;
        svg {
          path {
            fill: white;
          }
        }
      }
      .item-container-action-buttons-slider-new {
        position: absolute;
        bottom: 1rem;
        width: 100%;
        display: none;
        place-content: center;
        grid-auto-flow: column;
        animation: slideAnimation 0.5s;
        z-index: 2;
        &.show {
          display: flex;
        }
        button {
          @include button;
          font-size: 0.9rem;
          margin: 0 5px;
          padding: 10px;
          width: unset;
          box-shadow: 0 2px 8px #222;
          &:nth-last-of-type(1) {
            background-color: #7cb342;
            &:hover {
              color: var(--primaryHoverColor);
            }
          }
        }
      }
    }

    .edit-mode-action-button,
    .accept-service-action-button {
      position: absolute;
      right: 10px;
      bottom: 0;
      top: 0;
      margin: auto;
      display: grid;
      border-radius: 4px;
      background-color: var(--primaryHoverColor);
      &.service-closed {
        grid-template-columns: 40px;
        span {
          font: 300 1.4rem Russo One;
          color: orange;
          padding: 0px 2px;
        }
        svg {
          path {
            fill: #66cdaa;
          }
        }
      }
      div.double-action {
        display: grid;
        grid-template-rows: 1fr 1fr;
        div {
          display: grid;
          &:hover {
            background-color: salmon;
          }
        }
      }
      svg {
        height: 35px;
        width: 35px;
        position: relative;
        padding: 2px;
        z-index: 3;
        align-self: center;
        @media #{$laptop} {
          cursor: pointer;
        }
        path {
          fill: #6c7684;
        }
      }
      &.confirmed {
        background-color: mediumaquamarine;
        pointer-events: none;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .item-container-action-buttons-slider {
      position: absolute;
      bottom: -22px;
      width: 100%;
      display: none;
      place-content: center;
      grid-auto-flow: column;
      animation: slideAnimation 0.5s;
      z-index: 2;
      &.show {
        display: grid;
      }
      button {
        @include button;
        font-size: 0.9rem;
        margin: 0 5px;
        padding: 10px;
        width: unset;
        box-shadow: 0 2px 8px #222;
        &:nth-last-of-type(1) {
          background-color: #7cb342;
          &:hover {
            color: var(--primaryHoverColor);
          }
        }
      }
    }
  }
  .service-history-no-results {
    display: grid;
    align-content: center;
    label {
      @include label;
      // color: rgb(50, 65, 84);
      color: var(--mainNavColor);
      width: unset;
      display: grid;
    }
  }
  .service-history-confirmed-service {
    position: absolute;
    @include button;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@keyframes slideAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
